package com.pkg_dot_zip.kobwebportfoliotemplate.pages.projects

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/projects/")
@Composable
fun KobwebPortfolioTemplatePage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("projects/KobwebPortfolioTemplate.md", mapOf("title" to listOf("An automatic self-updating portfolio! 😱"), "author" to listOf("pkg-dot-zip"), "original_date_time" to listOf("12/03/2025 19:19"), "last_edited_date_time" to listOf("12/03/2025 20:43")))) {
        com.pkg_dot_zip.kobwebportfoliotemplate.components.layouts.BlogPostLayout("Kobweb Portfolio Template") {
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("It seems like every programmer, no matter the field, owns his/her own website nowadays! There are a lot of ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/Evavic44/portfolio-ideas") {
                    org.jetbrains.compose.web.dom.Text("amazing portfolios")
                }
                org.jetbrains.compose.web.dom.Text(" out there, including 3D interactive ones! However, these seem like a lot of work to make initially, so I'd like something simpler due to time constraints. ⌚")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("And that raises another interesting question: ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("How can we save as much time as possible with our portfolio?")
                }
            }
            com.varabyte.kobweb.silk.components.display.Callout(type = com.varabyte.kobweb.silk.components.display.CalloutType.TIP, label = "Success!", variant = com.varabyte.kobweb.silk.components.display.CalloutDefaults.Variant) {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text(" And you know I have the answer because you are on a website using this right now! 😁")
                }
            }
            org.jetbrains.compose.web.dom.H1(attrs = { id("but-i-don-t-do-web-development") }) {
                org.jetbrains.compose.web.dom.Text("But I don't do web-development... 🤔")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("So lets address the elephant in the room:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("I do not know JavaScript. 😢")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("I do not know CSS. 😭")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.Text("My phone is dead, so I can not call the zoo to take back their elephant. 🐘")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("So instead of learning these technologies, I thought it might be beneficial to work with something I already know! ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://kotlinlang.org/") {
                    org.jetbrains.compose.web.dom.Text("Kotlin")
                }
                org.jetbrains.compose.web.dom.Text("! There is a magnificent framework called ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://kobweb.varabyte.com") {
                    org.jetbrains.compose.web.dom.Text("Kobweb")
                }
                org.jetbrains.compose.web.dom.Text(" to assist men and women alike in developing their place on the worldwide web. Let's use that and start our project. 🤩")
            }
            org.jetbrains.compose.web.dom.H1(attrs = { id("the-idea") }) {
                org.jetbrains.compose.web.dom.Text("The Idea 💡")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Okay, so bear with me:")
            }
            com.varabyte.kobweb.silk.components.display.Callout(type = com.varabyte.kobweb.silk.components.display.CalloutType.NOTE, label = "Idea 💡", variant = com.varabyte.kobweb.silk.components.display.CalloutDefaults.Variant) {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text(" Our portfolio will update itself. 🔁")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("But how on earth would we achieve this?! Well, I have an interesting idea. All it requires is the ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://docs.github.com/en/rest") {
                    org.jetbrains.compose.web.dom.Text("GitHub Rest API")
                }
                org.jetbrains.compose.web.dom.Text(" & a ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://www.json.org/") {
                    org.jetbrains.compose.web.dom.Text("JSON")
                }
                org.jetbrains.compose.web.dom.Text(" parser. 👀")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("So lets say we have a beautiful new repository that does something incredible we would like to show on our portfolio. We'd have to add that to our website by creating some buttons and adding some links etc. Using the API however we can just pull all repositories we want and display them in a nice ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_grid_layout") {
                    org.jetbrains.compose.web.dom.Text("grid")
                }
                org.jetbrains.compose.web.dom.Text(".")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("We do this by declaring what users (and if we want to specific repositories) we want to grab the data from. Then we have a method where we can declare filters! This is handy if you want to ignore projects that only contains docs, or your own ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://docs.github.com/en/account-and-profile/setting-up-and-managing-your-github-profile/customizing-your-profile/managing-your-profile-readme") {
                    org.jetbrains.compose.web.dom.Text("secret profile on GitHub")
                }
                org.jetbrains.compose.web.dom.Text(". Personally, I also want to hide all forks:")
            }
            org.jetbrains.compose.web.dom.Pre { org.jetbrains.compose.web.dom.Code { org.jetbrains.compose.web.dom.Text(""" private fun shouldSkipRepo(repository: Repository): Boolean {
    if (repository.fork!!) return true
    if (repository.topics != null && repository.topics!!.contains("bad-code")) return true
    return false
}
""") } }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Okay, this sounds good. So this is where I initially ended the project. However, you've probably noticed that there is more text due to your well-functioning eyes and the scrollbar on the right of your screen. 👍🏻")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.H1(attrs = { id("the-blog-post-system") }) {
                org.jetbrains.compose.web.dom.Text("The \"Blog Post\" System! 📝")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Yeah, so when I actually wanted to show people what I made and linked them to my website they obviously still had to go through all my repositories manually. This is ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("not")
                }
                org.jetbrains.compose.web.dom.Text(" very ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("dope")
                }
                org.jetbrains.compose.web.dom.Text(", as the cool kids say! 😎 Plus, those readmes do not always contain images or videos of the project in action. 🎬")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Luckily I'm not only hip, but also a programmer! Kobweb has ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://kobweb.varabyte.com/docs/concepts/foundation/markdown") {
                    org.jetbrains.compose.web.dom.Text("extensive markdown support")
                }
                org.jetbrains.compose.web.dom.Text(". In fact, if you drag in a ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text(".md")
                }
                org.jetbrains.compose.web.dom.Text(" file in your resources it will generate a page for it. 📝")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.graphics.Image("/image/blogposts/kobwebportfoliotemplate/markdownFiles.png", "markdownFiles.png")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("However, since we want to automate as much as possible we need to think of a system that can check this automatically. Luckily, there are ways in Kobweb to check all pages (even ones that are generated by markdown).")
            }
            org.jetbrains.compose.web.dom.Pre { org.jetbrains.compose.web.dom.Code { org.jetbrains.compose.web.dom.Text("""ctx.router.routes.map { it.path }.filter { it.startsWith("/projects/") }
""") } }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("If we just give it the same name as the repository (and then look for a match by force converting both to ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://developer.mozilla.org/en-US/docs/Glossary/Kebab_case") {
                    org.jetbrains.compose.web.dom.Text("Kebab case")
                }
                org.jetbrains.compose.web.dom.Text(") and add a link to our new custom project page on the repository widget we are done. Bob is your uncle! 👨🏻")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.graphics.Image("/image/blogposts/kobwebportfoliotemplate/blogpostLink.png", "blogpostLink.png")
            }
            com.varabyte.kobweb.silk.components.display.Callout(type = com.varabyte.kobweb.silk.components.display.CalloutType.TIP, label = "Success!", variant = com.varabyte.kobweb.silk.components.display.CalloutDefaults.Variant) {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text(" And you know I have succeeded because you are on reading such a blog post right now! 😁")
                }
            }
            com.varabyte.kobweb.silk.components.display.Callout(type = com.varabyte.kobweb.silk.components.display.CalloutType.NOTE, label = null, variant = com.varabyte.kobweb.silk.components.display.CalloutDefaults.Variant) {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text(" If you are developing a site using Kobweb, please read their ")
                    com.varabyte.kobweb.silk.components.navigation.Link("https://kobweb.varabyte.com/docs/concepts/foundation/markdown") {
                        org.jetbrains.compose.web.dom.Text("documentation on markdown")
                    }
                    org.jetbrains.compose.web.dom.Text(". A lot is possible and it can save you a lot of time!")
                }
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.H1(attrs = { id("extra-features") }) {
                org.jetbrains.compose.web.dom.Text("Extra features! 🌟")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The site needs a special touch to look good! This is why I decided to display a random animated emoji on the front page! Such as this one:")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.graphics.Image("https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/People/Man%20Technologist.png", "typingMan")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("However, on some dates & times it is not random at all! For example, on the ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://de.wikipedia.org/wiki/Tag_der_Deutschen_Einheit") {
                    org.jetbrains.compose.web.dom.Text("Tag der Deutschen Einheit")
                }
                org.jetbrains.compose.web.dom.Text(" we show the German flag!")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.graphics.Image("/image/blogposts/kobwebportfoliotemplate/germanFlag.png", "germanFlag.png")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Not only that, but I also added a video background with a very low opacity. This gives the site a more 'lively' feeling. ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Look mom, I'm a UI designer")
                }
                org.jetbrains.compose.web.dom.Text("! 🤩✨")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.H1(attrs = { id("can-i-create-something-like-this-too") }) {
                org.jetbrains.compose.web.dom.Text("\"Can I create something like this too?\" 🤔💭")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Absolutely")
                }
                org.jetbrains.compose.web.dom.Text("! In fact, I created a ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/pkg-dot-zip/KobwebPortfolioTemplate") {
                    org.jetbrains.compose.web.dom.Text("portfolio template")
                }
                org.jetbrains.compose.web.dom.Text(" with TODOs noted everywhere where you need to put your own information. This means that (after setting up ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://kobweb.varabyte.com/") {
                    org.jetbrains.compose.web.dom.Text("Kobweb")
                }
                org.jetbrains.compose.web.dom.Text(") you can get your site running in just a few minutes! 🏃🏻‍♂️💨")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.H1(attrs = { id("conclusion") }) {
                org.jetbrains.compose.web.dom.Text("Conclusion 🤓")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I couldn't be happier with the outcome; this project really took off well! I did not imagine it would actually look good enough to be considered presentable and its functionality would be great! 😋")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I also never expected to have this much fun creating a website! If you have never dabbled into web-development and happen to know Kotlin I can not recommend you Kobweb enough. 🙏🏻")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("That is all for this post! 👋🏻")
            }
        }
    }
}
