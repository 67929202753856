package com.pkg_dot_zip.kobwebportfoliotemplate.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/")
@Composable
fun AboutPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("About.md", mapOf())) {
        com.pkg_dot_zip.kobwebportfoliotemplate.components.layouts.MarkdownLayout("About") {
            org.jetbrains.compose.web.dom.H1(attrs = { id("pkg-dot-zip") }) {
                org.jetbrains.compose.web.dom.Text("pkg-dot-zip")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("i-removed-this-page-because-of-privacy-concerns") }) {
                org.jetbrains.compose.web.dom.Text("I removed this page because of privacy concerns.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I removed this page because of privacy concerns. If you navigated to this site from my CV please continue reading that instead of using this site, or contact me directly (via Signal or by calling me) for more information.")
            }
            com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I am constantly learning new methodologies and technologies in the software engineering field. I’m actively seeking internships or projects where I can apply my skills, gain insights from seasoned professionals, and contribute to innovative and impactful solutions.")
            }
            com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Feel free to reach out if you’d like to chat about technology, education, or to share ideas!")
            }
        }
    }
}
