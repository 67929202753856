package com.pkg_dot_zip.kobwebportfoliotemplate.pages.projects

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/projects/")
@Composable
fun RadovanCommonExtensionsPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("projects/RadovanCommonExtensions.md", mapOf("title" to listOf("RadovanCommonExtensions - The C# package I use in ALL my projects! 📚"), "author" to listOf("pkg-dot-zip"), "original_date_time" to listOf("12/03/2025 14:30"), "last_edited_date_time" to listOf("12/03/2025 18:59")))) {
        com.pkg_dot_zip.kobwebportfoliotemplate.components.layouts.BlogPostLayout("RadovanCommonExtensions") {
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Ever had the need to reuse code from another project you made? Same!")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("When I was working on some assignments from university (and some big personal ones 👀) I noticed I kept writing the same extension methods over and over again. Looks like we need our own library! 😎")
            }
            com.varabyte.kobweb.silk.components.display.Callout(type = com.varabyte.kobweb.silk.components.display.CalloutType.QUESTION, label = null, variant = com.varabyte.kobweb.silk.components.display.CalloutDefaults.Variant) {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text(" Are you using this and missing something? Please let me know if you have suggestions! 😁")
                }
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.H1(attrs = { id("the-premise") }) {
                org.jetbrains.compose.web.dom.Text("The Premise")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I noticed that I kept writing the same extension methods in each project. For example, back in my Java days, I had the beautiful ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://docs.oracle.com/javase/8/docs/api/java/util/Collection.html#isEmpty--") {
                    org.jetbrains.compose.web.dom.Code {
                        org.jetbrains.compose.web.dom.Text(".isEmpty()")
                    }
                }
                org.jetbrains.compose.web.dom.Text(" method on collections. Not only that, but I could actually use ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://docs.oracle.com/javase/8/docs/api/java/util/Collection.html#addAll-java.util.Collection-") {
                    org.jetbrains.compose.web.dom.Code {
                        org.jetbrains.compose.web.dom.Text(".AddAll()")
                    }
                }
                org.jetbrains.compose.web.dom.Text(" on anything! In C# ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://learn.microsoft.com/en-us/dotnet/api/system.collections.generic.list-1.addrange?view=net-9.0") {
                    org.jetbrains.compose.web.dom.Code {
                        org.jetbrains.compose.web.dom.Text(".AddRange()")
                    }
                }
                org.jetbrains.compose.web.dom.Text(" is only available on ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/dotnet/runtime/blob/1d1bf92fcf43aa6981804dc53c5174445069c9e4/src/libraries/System.Private.CoreLib/src/System/Collections/Generic/List.cs#L245C13-L275C10") {
                    org.jetbrains.compose.web.dom.Code {
                        org.jetbrains.compose.web.dom.Text("List")
                    }
                }
                org.jetbrains.compose.web.dom.Text("s!")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("So C# took that all away from me! 😡 💢")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Well, that is kind of a silly reason to make a library, I obviously have more advanced use cases. Like some specific reflection techniques!")
            }
            org.jetbrains.compose.web.dom.Pre { org.jetbrains.compose.web.dom.Code { org.jetbrains.compose.web.dom.Text(""" public static object? InvokeTypeWithGenerics(this Type type, string methodName, Type[] genericTypes,
            object instance, object[]? parameters = null)
{
    var methodToInvoke = type.GetMethod(methodName,
        BindingFlags.Instance | BindingFlags.Static | BindingFlags.Public |
        BindingFlags.NonPublic);

    if (methodToInvoke is null)
        throw new ArgumentException(${'$'}"Method with name {methodName} in type {type.Name} does not exist.");
    return methodToInvoke.MakeGenericMethod(genericTypes).Invoke(instance, parameters);
}
""") } }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("What is going on here? 🤔 Well, in one of my projects I NEED to call a method with all kind of types, but generics in C# need to be tied down ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://learn.microsoft.com/en-us/dotnet/csharp/programming-guide/generics/constraints-on-type-parameters") {
                    org.jetbrains.compose.web.dom.Text("(generic constraints)")
                }
                org.jetbrains.compose.web.dom.Text(" in some cases. This was my implementation to bypass these constraints. ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("This is not good for performance")
                }
                org.jetbrains.compose.web.dom.Text(", but that was not the goal of the project. Interesting still right? 🤔")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("So we have some missing methods, and some specifics I need in two or more projects. I won't let Microsoft beat me on this one; time to make our own library. 🤓")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.H1(attrs = { id("the-goal") }) {
                org.jetbrains.compose.web.dom.Text("The Goal")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The goal of this project was to create a library anyone can install via NuGet and use in all their C# projects. However, C# has all kind of targets, like core, 8.0, 9.0-android and ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://learn.microsoft.com/en-us/dotnet/core/introduction#net-ecosystem") {
                    org.jetbrains.compose.web.dom.Text("more")
                }
                org.jetbrains.compose.web.dom.Text(". ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("If only there was a way to make a project compatible with all...")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("There is! It's called ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://learn.microsoft.com/en-us/dotnet/standard/net-standard?tabs=net-standard-1-0") {
                    org.jetbrains.compose.web.dom.Text("standard")
                }
                org.jetbrains.compose.web.dom.Text("! So let's create a project using that! 😋")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.H1(attrs = { id("how-does-this-work") }) {
                org.jetbrains.compose.web.dom.Text("How Does This Work?")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Very simple! Create a solution with a project targeting .net standard 2.1, then create another project for unit tests and target .net 8.0! ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Em {
                        org.jetbrains.compose.web.dom.Text("It is that easy.")
                    }
                }
                org.jetbrains.compose.web.dom.Text(" 😎")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("So implementing a method is as simple as adding it:")
            }
            org.jetbrains.compose.web.dom.Pre { org.jetbrains.compose.web.dom.Code { org.jetbrains.compose.web.dom.Text("""public static void AddAll<T>(this ICollection<T> collection, IEnumerable<T> elements)
{
    foreach (var element in elements) collection.Add(element);
}
""") } }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("And then running the unit tests! 👨🏻‍🔬")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("br")
            org.jetbrains.compose.web.dom.H1(attrs = { id("where-can-i-get-it-so-i-can-use-it-myself") }) {
                org.jetbrains.compose.web.dom.Text("Where can I get it, so I can use it myself?")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I obviously had to upload it to NuGet so people ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("(read other students)")
                }
                org.jetbrains.compose.web.dom.Text(" can use it for themselves.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The repository is available ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/pkg-dot-zip/RadovanCommonExtensions") {
                    org.jetbrains.compose.web.dom.Text("here")
                }
                org.jetbrains.compose.web.dom.Text(". ")
                com.varabyte.kobweb.compose.dom.GenericTag("br", null)
                org.jetbrains.compose.web.dom.Text(" The package is available ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://www.nuget.org/packages/RadovanCommonExtensions") {
                    org.jetbrains.compose.web.dom.Text("here")
                }
                org.jetbrains.compose.web.dom.Text(".")
            }
        }
    }
}
