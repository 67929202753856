package com.pkg_dot_zip.kobwebportfoliotemplate.pages.projects

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/projects/")
@Composable
fun JaklinSimpleVideoCompressorPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("projects/JaklinSimpleVideoCompressor.md", mapOf("title" to listOf("A Python-ese frontend for FFMPEG! 😎"), "author" to listOf("pkg-dot-zip"), "original_date_time" to listOf("17/03/2025 21:14"), "last_edited_date_time" to listOf("17/03/2025 22:10")))) {
        com.pkg_dot_zip.kobwebportfoliotemplate.components.layouts.BlogPostLayout("JaklinSimpleVideoCompressor") {
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Python, a language I genuinely dislike, but extremely popular. 🌟 I believe that if you dislike something, but you have never used or experienced it you can not really make an argument at all. Time to create a project in Python! 🐍")
            }
            com.varabyte.kobweb.silk.components.display.Callout(type = com.varabyte.kobweb.silk.components.display.CalloutType.NOTE, label = null, variant = com.varabyte.kobweb.silk.components.display.CalloutDefaults.Variant) {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text(" This project was created in under half an hour! ⌚ Isn't that crazy?! 🤯")
                }
            }
            org.jetbrains.compose.web.dom.H1(attrs = { id("what-are-we-creating") }) {
                org.jetbrains.compose.web.dom.Text("What are we creating? 😮")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Well, you see, I, for one, love my storage space! ~~Especially when I host media on a server of a friend of mine.~~")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("So it makes sense that we do not want to save everything. However, even when discarding all unneeded documents on your computer you might notice some everlasting enormous files: the videos. Unless you are hosting a cinema this should ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("not")
                }
                org.jetbrains.compose.web.dom.Text(" be the case! 🎥")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Introducing, ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://www.ffmpeg.org/") {
                    org.jetbrains.compose.web.dom.Text("FFMPEG")
                }
                org.jetbrains.compose.web.dom.Text(", a beautiful cross-platform solution to record, convert and stream audio and video. This means you can also use it to compress your videos. In fact, I have always used FFMPEG to convert my videos to the ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://www.webmproject.org/about/") {
                    org.jetbrains.compose.web.dom.Em {
                        org.jetbrains.compose.web.dom.Text(".webm")
                    }
                }
                org.jetbrains.compose.web.dom.Text(" format when I want to send it to friends!")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("However, sometimes that is not good enough, or you want a different format, framerate etc. When typing I sometimes made typos and only realised after waiting for the conversion to be finished. ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("We can avoid this with our own frontend GUI!")
                }
                org.jetbrains.compose.web.dom.Text(" 🤓")
            }
            org.jetbrains.compose.web.dom.H1(attrs = { id("the-good") }) {
                org.jetbrains.compose.web.dom.Text("The good 😇")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("So, getting started was extremely easy. I already had ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://www.jetbrains.com/pycharm/") {
                    org.jetbrains.compose.web.dom.Text("PyCharm")
                }
                org.jetbrains.compose.web.dom.Text(" installed from a university course on machine learning, so I just opened it and got started. After some research I decided I was going to use ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://docs.python.org/3/library/tkinter.html") {
                    org.jetbrains.compose.web.dom.Text("TKinter")
                }
                org.jetbrains.compose.web.dom.Text(" for the GUI because it looked really straightforward. Look at how easy it is to create a label and a button together:")
            }
            org.jetbrains.compose.web.dom.Pre { org.jetbrains.compose.web.dom.Code { org.jetbrains.compose.web.dom.Text("""def create_crf_quality_slider(self):
    crf_label = tk.Label(self.root, text="CRF Quality (0-51):")
    crf_label.grid(row=2, column=0, sticky=tk.W, padx=10)

    self.crf_value = tk.IntVar(value=23)
    self.crf_slider = tk.Scale(self.root, from_=0, to=51, orient=tk.HORIZONTAL, length=200, variable=self.crf_value)
    self.crf_slider.grid(row=2, column=1, padx=10)
""") } }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I then simply created the rest of GUI and that was it. We are almost done! 🤯")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.graphics.Image("/image/blogposts/jaklinsimplevideocompressor/gui.png", "gui.png")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Although extremely primitive, this is all I will ever need! I don't need control that precise. ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("I need that file-size to go down quickly.")
                }
                org.jetbrains.compose.web.dom.Text(" 🏃🏻‍♂️💨")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.graphics.Image("https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExaDJibDM3M2prNnc1anVqdXFsZGEyOGtlbnB4Y3Y5bWd4ejc0MWFveSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3orieWI2gfsviR3oRi/giphy.gif", "Compression")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("building") }) {
                org.jetbrains.compose.web.dom.Text("Building 🏗")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Using ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://www.pyinstaller.org/") {
                    org.jetbrains.compose.web.dom.Text("PyInstaller")
                }
                org.jetbrains.compose.web.dom.Text(" it was extremely easy to create an ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text(".exe")
                }
                org.jetbrains.compose.web.dom.Text(" that embeds all packages required.")
            }
            org.jetbrains.compose.web.dom.Pre { org.jetbrains.compose.web.dom.Code { org.jetbrains.compose.web.dom.Text("""pyinstaller --onefile --windowed --add-data "resources/icon.ico;resources" main.py
12:33
""") } }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("This does mean we have a 8MB executable. I suppose that is quite unfortunate, but it was it is. 🤷🏻‍♂️ You can download the program ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/pkg-dot-zip/JaklinSimpleVideoCompressor/releases") {
                    org.jetbrains.compose.web.dom.Text("here")
                }
                org.jetbrains.compose.web.dom.Text("!")
            }
            org.jetbrains.compose.web.dom.H1(attrs = { id("the-bad-the-ugly") }) {
                org.jetbrains.compose.web.dom.Text("The bad & the ugly 🤮🤢")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Python has no types! This makes it very easy to make mistakes. 😞 Look at this code:")
            }
            org.jetbrains.compose.web.dom.Pre { org.jetbrains.compose.web.dom.Code { org.jetbrains.compose.web.dom.Text("""@staticmethod
def stop_progress_window(progress_window, progress_bar):
    progress_bar.stop()
    progress_window.destroy()
""") } }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Can you tell me ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("exactly")
                }
                org.jetbrains.compose.web.dom.Text(" what type ")
                org.jetbrains.compose.web.dom.Code {
                    org.jetbrains.compose.web.dom.Text("progress_bar")
                }
                org.jetbrains.compose.web.dom.Text(" is? 🤔")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("It is also completely legal (as in ")
                org.jetbrains.compose.web.dom.Em {
                    org.jetbrains.compose.web.dom.Text("executable")
                }
                org.jetbrains.compose.web.dom.Text("; you will not go to jail) to return multiple objects. I guess this is just a ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://en.wikipedia.org/wiki/Tuple") {
                    org.jetbrains.compose.web.dom.Text("tuple")
                }
                org.jetbrains.compose.web.dom.Text(" with no types declared?")
            }
            org.jetbrains.compose.web.dom.Pre { org.jetbrains.compose.web.dom.Code { org.jetbrains.compose.web.dom.Text("""@staticmethod
def create_progress_window(app):
    # Progress window.
    progress_window = tk.Toplevel(app.root)
    progress_window.title("Compressing Video")
    progress_window.geometry("300x100")
    progress_label = tk.Label(progress_window, text="Compressing video, please wait...")
    progress_label.pack(pady=10)

    # Progress bar in window.
    progress_bar = ttk.Progressbar(progress_window, mode='indeterminate')
    progress_bar.pack(pady=10, fill=tk.X, padx=20)
    progress_bar.start()

    return progress_window, progress_bar
""") } }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("However, by far my biggest complaint is the lack of access modifiers. Python programmers wrap their function names with underscores and call it a day. This is ridiculous! 😭")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.graphics.Image("https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExYzk3c2JoZ2FxZWdxMngwMGlhOHQxZ2xsYWdxMnhvMzJiNXJvbzFxZCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/yRh4DNP0Itu2fymzyg/giphy.gif", "Ridiculous GIF")
            }
            org.jetbrains.compose.web.dom.H1(attrs = { id("what-did-i-learn") }) {
                org.jetbrains.compose.web.dom.Text("What did I learn?")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Well, after this project and the machine learning course I am confident enough to put Python on my CV, since I fully understand the language. However, that does not mean I like it. I will choose C++, Kotlin & C# all over Python without hesitating.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("However, I do feel like I need to point out that I do not think it is a bad language. ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://docs.python.org/3/faq/general.html#why-was-python-created-in-the-first-place") {
                    org.jetbrains.compose.web.dom.Text("Python was never intended for a programmer like me to use it.")
                }
                org.jetbrains.compose.web.dom.Text(" It is made for non-programmers! So if you are reading this and think")
            }
            com.varabyte.kobweb.compose.dom.GenericTag("blockquote") {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("You are so wrong! Python is the best!!! 🥰")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I am happy that you feel that way! It's just not made for everyone. 🙂")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("If you want to download this project, checkout the ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/pkg-dot-zip/JaklinSimpleVideoCompressor/releases") {
                    org.jetbrains.compose.web.dom.Text("releases")
                }
                org.jetbrains.compose.web.dom.Text(" on GitHub. That concludes this tiny project. Cya! 👋🏻")
            }
        }
    }
}
